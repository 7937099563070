import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom'
import '@/styles/tailwind.css'
import Home from './app/Home'
import Contact from './app/contact/page'
import Careers from './app/Careers'
import Investors from './app/Investors'
import Blog from './app/Blog'

const ComingSoon = () => {
  return <div>Coming soon...</div>
}

function ScrollToAnchor() {
  const location = useLocation()
  const lastHash = useRef('')
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
    }
    const ele = document.getElementById(lastHash.current)
    if (lastHash.current && ele) {
      setTimeout(() => {
        ele?.scrollIntoView({ behavior: 'smooth', block: 'start' })
        lastHash.current = ''
      }, 100)
    }
  }, [location])

  return null
}

const ScrollToTop = () => {
  useEffect(() => {
    // Disable automatic scroll restoration
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    // Scroll to the top on initial load
    window.scrollTo(0, 0)

    return () => {
      if ('scrollRestoration' in window.history) {
        window.history.scrollRestoration = 'auto'
      }
    }
  }, [])

  return null
}

const App = () => {
  ScrollToAnchor()
  ScrollToTop()
  return (
    <div>
      <main>
        <Outlet />
      </main>
    </div>
  )
}
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // { path: '', element: <ComingSoon /> },
      {
        path: '',
        element: <Home />,
      },
      { path: 'careers', element: <Careers /> },
      { path: 'investors', element: <Investors /> },
      { path: 'blog', element: <Blog /> },
    ],
  },
])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
