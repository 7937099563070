import clsx from 'clsx'

function Office({
  name,
  children,
  invert = false,
}: {
  name: string
  children: React.ReactNode
  invert?: boolean
}) {
  return (
    <address
      className={clsx(
        'text-sm not-italic',
        invert ? 'text-neutral-300' : 'text-neutral-600',
      )}
    >
      <strong className={invert ? 'text-white' : 'text-neutral-950'}>
        {name}
      </strong>
      <br />
      {children}
    </address>
  )
}

export function Offices({
  invert = false,
  ...props
}: React.ComponentPropsWithoutRef<'ul'> & { invert?: boolean }) {
  return (
    <ul role="list" {...props}>
      <li>
        <Office name="Head Office" invert={invert}>
          (306) 668-4446
          <br />
          211 Idylwyld Dr. North
          <br />
          Saskatoon, Saskatchewan, S7L 6V6
        </Office>
      </li>
      {/* <li>
        <Office name="Canada Budget Cars" invert={invert}>
          (306) 954-7030
          <br />
          2240 Ave. C North
          <br />
          Saskatoon, Saskatchewan, S7L 6C4
        </Office>
      </li>
      <li>
        <Office name="Langenburg Motors" invert={invert}>
          1-866-983-9122
          <br />
          525 Kaiser William Ave. (Highway 16)
          <br />
          Langenburg, Saskatchewan, S0A 2A0
        </Office>
      </li>
      <li>
        <Office name="Canada CarMatcher" invert={invert}>
          (306) 668-4446
          <br />
          369 6th Ave. North
          <br />
          Yorkton, Saskatchewan, S3N 3L2
        </Office>
      </li> */}
      {/* <div className="mt-10 border-t border-white/10 pt-10"> */}
      <li>
        <Office name="Investor Relations" invert={invert}>
          For inquiries, please reach out to{' '}
          <a href="mailto:info@prairieonemotorgroup.ca">
            info@prairieonemotorgroup.ca
          </a>
        </Office>
      </li>
      {/* </div> */}
    </ul>
  )
}
