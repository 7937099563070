import { Container } from '@/components/Container'
import { FadeIn } from '@/components/FadeIn'
// import imageLaptop from '../images/service.jpeg'
import Layout from './layout'
import { SectionImage } from '@/components/SectionImageLeft'
import { ListItem } from '@/components/List'
import careers from '@/images/careers.jpg'
import futurestart from '@/images/futurestart.jpg'
import Link from 'next/link'

interface SubSectionProps {
  title: string
  description: string
  imageSrc: string
  imageAlt: string
}

export function SubSection({
  title,
  description,
  imageSrc,
  imageAlt,
}: SubSectionProps) {
  return (
    <div className="mb-8 flex flex-col items-center md:flex-row">
      <div className="">
        {/* <Image src={imageSrc} alt={imageAlt} width={500} height={300} /> */}
      </div>
      <div className="mt-4 md:ml-8 md:mt-0 md:w-1/2">
        <h2 className="text-2xl font-semibold text-neutral-950">{title}</h2>
        <p className="mt-2 text-neutral-700">{description}</p>
      </div>
    </div>
  )
}

export default function Careers() {
  // let caseStudies = (await loadCaseStudies()).slice(0, 3)

  const coreValuesListItems = [
    <>
      <ListItem title="Agility">
        We swiftly adapt to changes and challenges, leveraging flexibility in
        our processes and thinking to meet and exceed demands.
      </ListItem>
      <ListItem title="Community">
        We embrace and encourage a diverse and inclusive environment, fostering
        varied perspectives and enhancing creativity. We are committed to giving
        back to our Saskatchewan community through initiatives like local
        sponsorships, charity events, and volunteer work. By nurturing a sense
        of belonging and collaboration, we aim to build a stronger, more vibrant
        community for all.
      </ListItem>
      <ListItem title="Innovation">
        We are committed to continuous improvement and leading-edge practices,
        investing in new technologies and ideas to drive progress.
      </ListItem>
      <ListItem title="Customer Centric">
        We go beyond selling cars—we create a bespoke car-buying experience
        tailored to your needs. Whether you're purchasing a brand-new vehicle or
        one that's new to you, we redefine the Prairie car-buying experience
        with personalized protection services and packages, giving you peace of
        mind for all of life's journeys.
      </ListItem>
    </>,
  ]

  const weOfferListItems = [
    <ListItem title="Ownership">
      PrairieOne Motor Group offers individuals a comprehensive support system to
      own and operate their own dealership, providing essential training and
      resources. They facilitate access to a wide range of vehicles, financing
      options, and marketing tools to ensure successful dealership operations.
      By leveraging their extensive industry experience, PrairieOne Motor Group
      empowers entrepreneurs to thrive in the automotive market.
    </ListItem>,
    <ListItem title="Competitive Salary">
      Our employees are our greatest asset; we strive to provide them with a
      compensation package that reflects their value to the organization.
    </ListItem>,
    <ListItem title="Benefits Package">
      We offer a comprehensive benefits package designed to support our
      employees' well-being, health, and financial security.
    </ListItem>,
    <ListItem title="High-Performing Environment">
      Committed to providing our employees with the resources, tools, and
      training they need to succeed and thrive in their roles.
    </ListItem>,
  ]

  return (
    <Layout>
      <Container className="mt-24 sm:mt-36 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            Careers
          </h1>
          <div className="pb-12">
            Joining PrairieOne Motor Group, the fastest growing automotive group
            in the prairies, is an opportunity to be part of a dynamic and
            diverse team of automotive enthusiasts. At PrairieOne Motor Group, we
            are committed to fostering an environment that nurtures
            entrepreneurs and career-minded individuals, providing them with the
            tools and support needed to thrive in the automotive industry. Our
            diverse team reflects our dedication to inclusivity and innovation,
            ensuring that every member has the chance to contribute their unique
            perspective and skills. Whether you're passionate about cars or
            eager to build a successful career, PrairieOne Motor Group offers a
            vibrant community where you can grow and excel.
          </div>
        </FadeIn>
      </Container>
      <div className="pt-4">
        <SectionImage
          title="Core Values"
          id="core-values"
          listItems={coreValuesListItems}
          image={careers}
          imagePosition="left"
        />
      </div>
      <SectionImage
        title="We Offer"
        id="core-values"
        listItems={weOfferListItems}
        image={futurestart}
        imagePosition="right"
      />
      <div className="flex justify-center">
        <div className="mt-12 flex   flex-col justify-center space-y-8 md:flex-row md:space-x-8 md:space-y-0"></div>
      </div>
      <Container className="pt-8">
        <div className="mt-4 text-4xl font-bold">
          PrairieOne Motor Group: Empower Your Journey Forward With Us
        </div>
        <div className="mt-4">
          For more information, contact{' '}
          <Link
            href={`mailto:careers@prairieonemotorgroup.ca`}
            className="font-bold text-neutral-600 hover:text-neutral-950"
          >
            careers@prairieonemotorgroup.ca
          </Link>
          .
        </div>
      </Container>
    </Layout>
  )
}
