import { type Metadata } from 'next'

import { RootLayout } from '@/components/RootLayout'

import '@/styles/tailwind.css'

export const metadata: Metadata = {
  title: {
    template: '%s - TODO',
    default: 'TODO - change this',
  },
}

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <div lang="en" className="h-full bg-neutral-950 text-base antialiased">
      <div className="flex min-h-full flex-col">
        <RootLayout>{children}</RootLayout>
      </div>
    </div>
  )
}
