import { type Metadata } from 'next'

import { ContactSection } from '@/components/ContactSection'
import { Container } from '@/components/Container'
import { FadeIn } from '@/components/FadeIn'
import { List, ListItem } from '@/components/List'
import { SectionIntro } from '@/components/SectionIntro'
import { StylizedImage } from '@/components/StylizedImage'
import { Testimonial } from '@/components/Testimonial'
import logoPhobiaDark from '../images/clients/phobia/logo-dark.svg'
import service from '../images/service.jpeg'
import trucks from '../images/trucks.jpeg'
import sunset from '../images/sunset.jpeg'
// import imageLaptop from '../images/service.jpeg'
import Layout from './layout'
import { Carousel } from '../components/Carousel'
import { Dealerships } from './Dealerships'
import { Brands } from './Brands'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { SectionImage } from '@/components/SectionImageLeft'
function Services() {
  const listItems = [
    <>
      <ListItem title="Customer-Centric Service">
        We prioritize your satisfaction with personalized, transparent, and
        friendly service from the moment you step into our dealership.
      </ListItem>
      <ListItem title="Wide Selection">
        Our extensive inventory features a diverse range of new and pre-owned
        vehicles to suit every lifestyle and budget.
      </ListItem>
      <ListItem title="Expert Staff">
        Our knowledgeable and dedicated team is here to guide you through every
        step of the car-buying process with expert advice and support.
      </ListItem>
      <ListItem title="Community Commitment">
        As a proud member of the community, we actively participate in local
        events and initiatives to give back and support our neighbors.
      </ListItem>
      <ListItem title="Comprehensive Care">
        From financing options to maintenance and repair services, we offer
        comprehensive support to ensure your car ownership experience is smooth
        and enjoyable.
      </ListItem>
    </>,
  ]
  return (
    <SectionImage
      title="Who we are"
      id={'about-us'}
      listItems={listItems}
      image={trucks}
    />
  )
}

export const metadata: Metadata = {
  description:
    'We are a development studio working at the intersection of design and technology.',
}

export default function Home() {
  // let caseStudies = (await loadCaseStudies()).slice(0, 3)

  return (
    <Layout>
      <Container className="mt-24 sm:mt-36 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            The Prairies Fastest Growing Autogroup
          </h1>
          <p className="mt-6 text-xl text-neutral-600">
            Here at PrairieOne Motor Group it's our mission to be the automotive
            leader amongst the Prairie Provinces in Canada. We provide the
            largest selection of new and used vehicles as well as extensive
            protection and automotive services.
          </p>
        </FadeIn>
      </Container>
      <Container className="pt-14">
        <img src={sunset} alt="prairies" style={{ borderRadius: '5%' }} />
      </Container>
      <Container id="our-story">
        <h1 className="pt-4 font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
          Our Story
        </h1>
        <p className="mt-6 text-xl text-neutral-600">
        PrairieOne Motor Group was established with dual visions: first, to enhance the automotive buying experience across the Prairie Provinces, and second, to build a lasting legacy of next-generation automotive professionals & owners. Our journey began on the Prairies, where we recognized the need for a dealership that genuinely understands and meets the specific demands of prairie regions. With this in mind, we set out to create a dealership group that excels in selection, service, and innovation.
          <br />
          <br />
          Our commitment to integrity, forward-thinking, and customer satisfaction has been the foundation of our business since its inception. We believe that purchasing a vehicle should be a pleasant and straightforward experience, and we have structured our business around that ideal. Simultaneously, we are dedicated to fostering the growth of the next generation of leaders in the automotive industry, ensuring that our legacy extends beyond just transactions to include mentorship and community building.
          <br />
          <br />
          With over three decades of collective experience in the automotive industry, the team at PrairieOne Motor Group has held various key roles, equipping us with extensive expertise in sales, management, and strategic growth.  Over the years, our company has evolved from a single dealership to a trusted name in the automotive sector. As a true prairie original, PrairieOne Motor Group embodies the spirit of driving the community forward with a commitment to quality, hometown values, and a vision for the future of the industry.
        </p>
      </Container>

      <Dealerships />
      <Brands />

      <>
        <SectionIntro
          eyebrow=""
          title=" Looking to Service Your Vehicle?"
          className="mt-24"
        >
          <p>
            PrairieOne Motor Group is your partner for all Automotive Service,
            Repair, and Accessory Purchases in the Prairie Provinces. Our
            experienced team is readily available to get you (our valued
            customer) whatever you need to keep your new or pre-owned vehicle
            road-worthy for years to come.
          </p>
        </SectionIntro>
        <Container className="mt-16">
          <div className="lg:flex lg:items-center lg:justify-end">
            <div className="mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] lg:pl-4">
              <Carousel />
            </div>
            <div className="flex  justify-end lg:w-1/2 lg:pr-12">
              <FadeIn className="z-0 w-[33.75rem] flex-none lg:w-[29rem]">
                <StylizedImage
                  src={service}
                  sizes="(min-width: 1024px) 41rem, 31rem"
                  className="justify-center lg:justify-end"
                  grayscale
                />
              </FadeIn>
            </div>
          </div>
        </Container>
      </>

      {/* <CaseStudies caseStudies={caseStudies} /> */}

      <Services />

      <ContactSection />
    </Layout>
  )
}
