import { Container } from '@/components/Container'
import { FadeIn } from '@/components/FadeIn'
// import imageLaptop from '../images/service.jpeg'
import Layout from './layout'
import { SectionImage } from '@/components/SectionImageLeft'
import { ListItem } from '@/components/List'
import handshake from '@/images/handshake.jpg'
import csr from '@/images/csr.jpg'
import transport from '@/images/transport.jpg'
import Link from 'next/link'

export default function Investors() {
  const corpListItems = [
    <>
      <ListItem title="Sustainable Operations">
        Implementing eco-friendly green technologies and practices across all
        our dealerships to reduce our carbon footprint and promote environmental
        sustainability.
      </ListItem>
      <ListItem title="Ethical Governance">
        Adhering to the highest standards of corporate governance, transparency,
        and ethical business conduct.
      </ListItem>
      <ListItem title="Stakeholder Engagement">
        Actively engaging with our stakeholders, including employees, customers,
        investors, and the community, to ensure their needs and concerns are
        addressed.
      </ListItem>
      <ListItem title="Transparency">
        Maintaining open and honest communication about our business practices,
        performance, and corporate decisions to build trust and accountability.
      </ListItem>
      <ListItem title="Compliance">
        Staying updated with laws and regulations and constantly educating
        ourselves to ensure our operations meet all legal requirements and
        industry standards.
      </ListItem>
    </>,
  ]

  const socialListItems = [
    <>
      <ListItem title="Community Support">
        Partnering with local organizations and charities to support education,
        healthcare, and community development projects that foster strong
        community relationships. We are actively engaged in organizing and
        sponsoring these community events and provide donations in support of
        the local charities.
      </ListItem>
      <ListItem title="Employee Volunteering">
        Encouraging our employees to volunteer their time and skills to local
        causes and community projects.
      </ListItem>
      <ListItem title="Inclusive Workplace">
        Fostering a diverse and inclusive workplace where all employees are
        valued and given equal opportunities to succeed.
      </ListItem>
      <ListItem title="Educational Opportunities for Employees">
        Offering continuous learning and development programs to enhance the
        skills and knowledge of our employees, ensuring they grow and advance in
        their careers.
      </ListItem>
      <ListItem title="Expert HR Team">
        Maintaining a dedicated Human Resources team to support employee
        development, address concerns, and ensure a positive and productive work
        environment.
      </ListItem>
      <ListItem title="Employee Well-being">
        Ensuring a supportive and inclusive work environment that promotes the
        well-being of our employees.
      </ListItem>
    </>,
  ]

  const managingPartnersListItems = [
    <>
      <ListItem title="Market Expansion">
        Successfully expanding to new markets while maintaining profitability.
      </ListItem>
      <ListItem title="Innovative Initiatives">
        Launching groundbreaking initiatives in lead generation and brand
        awareness.{' '}
      </ListItem>
      <ListItem title="Strategic Partnerships">
        Building strong partnerships with leading global automotive brands such
        as Ford, Chevrolet, Acura, and Subaru.
      </ListItem>
      <ListItem title="Profitability Turnaround">
        Transforming non-profitable OEM franchises into profitable ventures
        within just 30 days.
      </ListItem>
      <ListItem title="Marketing tools and AI">
        Incorporating the latest in automotive technology utilizing advanced
        marketing tools and artificial intelligence to enhance customer
        engagement, streamline operations, and drive sales growth.
      </ListItem>
    </>,
  ]

  return (
    <Layout>
      <Container className="mt-24 sm:mt-36 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="mb-5 font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            Investor Relations
          </h1>
        </FadeIn>
        <div className="pb-12">
          Become a partner with us at PrairieOne Motor Group and join a team of
          passionate automotive enthusiasts dedicated to driving success in one
          of Canada's fastest growing industries. As part of our managing
          partner team, you'll have the unique opportunity to invest in a
          thriving business, leveraging your expertise and passion for
          automobiles to help shape the future of the automotive industry in the
          prairies. We are committed to fostering growth and innovation,
          offering our partners unparalleled support and resources to ensure
          mutual success. This is your chance to be part of a dynamic,
          forward-thinking organization where your contributions will make a
          significant impact. Join us and accelerate your journey in the
          automotive world with PrairieOne Motor Group.
        </div>
      </Container>
      <div className="pb-4">
        <SectionImage
          title="Corporate Responsibility"
          id="corporate-responsibility"
          listItems={corpListItems}
          image={handshake}
          imagePosition="left"
        />
      </div>
      <div className="pb-4">
        <SectionImage
          title="Social Responsibility"
          id="social-responsibility"
          listItems={socialListItems}
          image={csr}
          imagePosition="right"
        />
      </div>
      <div className="pb-4">
        <SectionImage
          title="Managing Partners"
          id="managing-partners"
          listItems={managingPartnersListItems}
          image={transport}
          imagePosition="left"
          preBlurb={`At PrairieOne MotorGroup, our growing team of managing partners
              brings a diverse wealth of experience and expertise from various
              fields, including automotive, marketing, telecommunications,
              technology, insurance, and industrial operations. Together, we
              have achieved remarkable milestones by focusing on developing
              today's automotive professionals and fostering their growth within
              the industry.`}
        />
      </div>
      <Container>
        <div className="mt-4 text-4xl font-bold">
          PrairieOne Motor Group: Empowering Your Journey Forward
        </div>
        <div className="mt-4 text-lg">
          At PrairieOne Motor Group - you can catapult your career forward by
          taking advantage of our Managing Partner trainee investor program.
          Designed to build todays passionate automotive professionals to become
          tomorrows automotive leaders.
        </div>
        <div className="mt-4">
          For more information, contact{' '}
          <Link
            href={`mailto:investorrelations@prairieonemotorgroup.ca`}
            className="font-bold text-neutral-600 hover:text-neutral-950"
          >
            investorrelations@prairieonemotorgroup.ca
          </Link>
          .
        </div>
      </Container>
    </Layout>
  )
}
