import { Container } from '@/components/Container'
import { FadeIn } from '@/components/FadeIn'
// import imageLaptop from '../images/service.jpeg'
import Layout from './layout'

export default function Blog() {
  // let caseStudies = (await loadCaseStudies()).slice(0, 3)

  return (
    <Layout>
      <Container className="mt-24 sm:mt-36 md:mt-56">
        <FadeIn className="max-w-3xl">
          <h1 className="font-display text-5xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
            Blog
          </h1>
          Coming soon...
        </FadeIn>
      </Container>
    </Layout>
  )
}
