import React, { useState } from 'react'
import { Testimonial } from './Testimonial'
import { FadeIn } from './FadeIn'
import { GridPattern } from './GridPattern'
import clsx from 'clsx'

export const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const slides = [
    {
      testimonial: `My experience at Langenburg was fantastic; Nick and the Staff was knowledgeable, honest, and made
      the car-buying process seamless and stress-free. I highly recommend them
      to anyone looking for a new vehicle, as they exceeded all my expectations
      with their exceptional service and transparency.`,
      name: 'Ryan B.',
    },
    {
      testimonial: `Canada Budget Cars provided outstanding service for my 2022 Honda Civic
      from start to finish. I couldn't be happier with my new car and included
      service package. Thanks Arun!`,
      name: 'Amber T.',
    },
    {
      testimonial: `Buying my car from CarMatcher was a breeze. The staff was incredible,
      Graham made the entire process smooth and enjoyable.`,
      name: 'Rick J.',
    },
  ]

  const handlePrev = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const handleNext = () => {
    const isLastSlide = currentIndex === slides.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  return (
    <div className="relative isolate py-16 sm:py-28 md:py-32">
      <FadeIn>
        <div className="relative  w-full px-2">
          <div className="relative overflow-hidden rounded-lg shadow-lg">
            <GridPattern
              className="absolute inset-0 -z-10 h-full w-full fill-neutral-100 stroke-neutral-950/5 [mask-image:linear-gradient(to_bottom_left,white_50%,transparent_60%)]"
              yOffset={-256}
            />
            <div
              className="flex transform transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <div className="w-full flex-shrink-0" key={index}>
                  <div className="p-6">
                    <figure className="mx-auto max-w-4xl">
                      <blockquote className="relative font-display text-2xl font-medium tracking-tight text-neutral-950 sm:text-3xl">
                        <p className="before:content-['“'] after:content-['”'] sm:before:absolute sm:before:right-full">
                          {slide.testimonial}
                        </p>
                      </blockquote>
                      <p className="relative font-display text-2xl font-medium tracking-tight text-neutral-950 sm:text-3xl">
                        -{slide.name}
                      </p>
                    </figure>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={handlePrev}
            className="absolute left-0 top-1/2 -translate-y-1/2 transform rounded-full bg-gray-800 p-2 text-white"
          >
            &#10094;
          </button>
          <button
            onClick={handleNext}
            className="absolute right-0 top-1/2 -translate-y-1/2 transform rounded-full bg-gray-800 p-2 text-white"
          >
            &#10095;
          </button>
        </div>
      </FadeIn>
    </div>
  )
}

export default Carousel
