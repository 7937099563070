import { Container } from '@/components/Container'
import { FadeIn, FadeInStagger } from '@/components/FadeIn'
import ford from '../images/ford.png'
import canadabudgetcars from '../images/canadabudgetcars.png'
import carmatcher from '../images/brands/canadacarmatcher.png'
import prairieonemg from '../images/brands/pa.png'
import resourcery from '../images/brands/resourcery.png'
import btc from '../images/brands/canadabudgettrucks.png'
import cods from '../images/brands/canadaonedealerservices.png'
import budgetcars from '../images/brands/canadabudgetcars.png'
import partsource from '../images/brands/budgetpartsource.png'
import carget from '../images/brands/cargetautomotive.png'
import prairieexpress from '../images/brands/prairieexpress.png'
import budgettire from '../images/brands/budgettire.png'
import fordlangenberg from '../images/brands/fordlangenberg.png'
import prarieez from '../images/brands/prairieez.png'
import prairierental from '../images/brands/prairieeconomybrand.png'
import GMC from '../images/brands/GMC.png'
import Buick from '../images/brands/buick.png'
import Chevrolet from '../images/brands/chevrolet.png'
import poleasingco from '../images/brands/poleasingco.png'

// import { type CaseStudy, type MDXEntry, loadCaseStudies } from '@/lib/mdx'
const clients = [
  {
    text: '',
    logo: prairieonemg,
    link: '/',
    alt: 'PrairieOne Motor Group',
  },
  {
    text: '',
    logo: resourcery,
    link: 'https://resourcery.ca',
    alt: 'Resourcery',
  },
  {
    text: '',
    logo: cods,
    link: 'https://www.canadaonedealerservices.ca/',
    alt: 'CanadaOne Dealer Services',
  },
  {
    text: '',
    logo: fordlangenberg,
    link: 'https://www.langenburgmotors.com/',
    alt: 'Langenburg Motors',
  },
  {
    text: '',
    logo: carmatcher,
    link: 'https://www.carmatcher.ca/',
    alt: 'Carmatcher',
  },
  {
    text: '',
    logo: btc,
    link: 'https://www.canadabudgettrucks.ca/',
    alt: 'Budget Truck Center',
  },
  {
    text: '',
    logo: budgetcars,
    link: 'https://www.canadabudgettrucks.ca/',
    alt: 'Budget Truck Center',
  },
  {
    text: '',
    logo: partsource,
    link: '',
    alt: 'Part Source',
  },
  // {
  //   text: '',
  //   logo: carget,
  //   link: 'https://www.carget.ca/',
  //   alt: 'Carget',
  // },
  {
    text: '',
    logo: prairieexpress,
    link: '',
    alt: 'Prairie Express',
  },
  {
    text: '',
    logo: budgettire,
    link: '',
    alt: 'Budget Tire',
  },
  {
    text: '',
    logo: prarieez,
    link: '',
    alt: 'Prairie EZ Financing',
  },
  {
    text: '',
    logo: prairierental,
    link: '',
    alt: 'Prairie EZ Financing',
  },
  {
    text: '',
    logo: poleasingco,
    link: '',
    alt: 'Prairie EZ Financing',
  },
  {
    text: '',
    logo: GMC,
    link: '',
    alt: 'GMC',
  },
  {
    text: '',
    logo: Buick,
    link: '',
    alt: 'Buick',
  },
  {
    text: '',
    logo: Chevrolet,
    link: '',
    alt: 'Chevrolet',
  },
]
export function Brands() {
  return (
    <div className=" rounded-4xl ">
      <Container>
        <FadeIn className="flex items-center gap-x-8">
          <h2 className="text-center font-display text-sm font-semibold tracking-wider text-black sm:text-left">
            Our Brands
          </h2>
          <div className="h-px flex-auto bg-neutral-800" />
        </FadeIn>
        <FadeInStagger faster>
          <ul
            role="list"
            className="mt-10 grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6"
          >
            {clients.map(({ text, logo, link, alt }, index) => (
              <li key={index}>
                <FadeIn>
                  <div className="flex flex-col justify-center">
                    <a href={link} target="_blank">
                      <div className="flex justify-center">
                        <img src={logo} alt={alt} width="200px" />
                      </div>
                      <div className="text-Black flex justify-center whitespace-nowrap font-bold">
                        {text}
                      </div>
                    </a>
                  </div>
                </FadeIn>
              </li>
            ))}
          </ul>
        </FadeInStagger>
      </Container>
    </div>
  )
}
