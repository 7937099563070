import { Container } from '@/components/Container'
import { FadeIn } from './FadeIn'
import { List, ListItem } from './List'
import { SectionIntro } from './SectionIntro'
import { StylizedImage } from './StylizedImage'

interface Props {
  image: string
  listItems: JSX.Element[]
  title: string
  id: string
  imagePosition?: 'left' | 'right'
  preBlurb?: string
  postBlurb?: string
}

export const SectionImage = ({
  title,
  id,
  image,
  listItems,
  imagePosition = 'left',
  preBlurb = '',
  postBlurb = '',
}: Props) => {
  const isImageLeft = imagePosition === 'left'

  return (
    <div id={id}>
      <SectionIntro eyebrow="" title={title} className=""></SectionIntro>
      <Container className="mt-5">
        {preBlurb ? <div className="mb-6">{preBlurb}</div> : ''}
        <div
          className={`lg:flex lg:items-center ${isImageLeft ? 'lg:flex-row' : 'lg:flex-row-reverse'} lg:justify-end`}
        >
          <div
            className={`flex justify-center lg:w-1/2 ${isImageLeft ? 'lg:justify-end lg:pr-12' : 'lg:justify-start lg:pl-12'}`}
          >
            {image && (
              <FadeIn className="w-[33.75rem] flex-none lg:w-[45rem]">
                <StylizedImage
                  src={image}
                  sizes="(min-width: 1024px) 41rem, 31rem"
                  className={`justify-center ${isImageLeft ? 'lg:justify-end' : 'lg:justify-start'}`}
                />
              </FadeIn>
            )}
          </div>
          <List
            className={`mt-16 lg:mt-0 lg:w-1/2 lg:min-w-[33rem] ${isImageLeft ? 'lg:pl-4' : 'lg:pr-4'}`}
          >
            {listItems}
          </List>
        </div>
        {postBlurb}
      </Container>
    </div>
  )
}
